export default {
  namespaced: true,
  state: {
    invoices: [],
    count: 0,
  },
  getters: {
    invoices: (state) => state.invoices,
    count: (state) => state.count,
  },
  mutations: {
    invoices: (state, payload) => (state.invoices = payload),
    count: (state, payload) => (state.count = payload),
  },
  actions: {
    list({ commit }, queryParams) {
      return this.$apiService
        .get("/invoices", {
          params: queryParams,
        })
        .then((res) => {
          commit("invoices", res?.data?.data ?? []);
          commit("count", res?.data?.count ?? 0);
          return res;
        });
    },
    create({}, payload) {
      return this.$apiService.post("/invoices", payload);
    },
    show({}, id) {
      return this.$apiService.get(`/invoices/${id}`);
    },
    update({}, { id, data }) {
      return this.$apiService.put(`/invoices/${id}`, data);
    },
    updateStatus({}, { id, data }) {
      return this.$apiService.patch(`/update-invoice-status/${id}`, data);
    },
    destroy({}, id) {
      return this.$apiService.delete(`/invoices/${id}`);
    },
    saveDocumentAssignment({ commit }, payload) {
      return this.$apiService.post(
        "/global-settings/document-assignment",
        payload
      );
    },
    documentAssignmentList({ commit }, queryParams) {
      return this.$apiService
        .get("/global-settings/document-assignment", {
          params: queryParams,
        })
        .then((res) => {
          return res;
        });
    },
    download({}) {
      return this.$apiService.get(`/invoices/download/csv`).then((response) => {
        // Get the data from the response
        const data = response.data;

        // Create a Blob from the data
        const blob = new Blob([data], { type: "text/csv" });

        // Create a URL for the file
        const url = window.URL.createObjectURL(blob);

        // Create a link element
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "invoice.csv");

        // Add the link to the DOM and click it to initiate the download
        document.body.appendChild(link);
        link.click();
      });
    },
    downloadLatestCSV({}) {
      return this.$apiService
        .get(`/invoices/download/latest-csv`)
        .then((response) => {
          // Get the data from the response
          const data = response.data;

          // Create a Blob from the data
          const blob = new Blob([data], { type: "text/csv" });

          // Create a URL for the file
          const url = window.URL.createObjectURL(blob);

          // Create a link element
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "invoice.csv");

          // Add the link to the DOM and click it to initiate the download
          document.body.appendChild(link);
          link.click();
        });
    },
    downloadInvoicePlan({}) {
      return this.$apiService
        .get(`/invoices/export-invoice-plan`)
        .then((response) => {
          // Get the data from the response
          const data = response.data;

          // Create a Blob from the data
          const blob = new Blob([data], { type: "text/csv" });

          // Create a URL for the file
          const url = window.URL.createObjectURL(blob);

          // Create a link element
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "invoice-plan.csv");

          // Add the link to the DOM and click it to initiate the download
          document.body.appendChild(link);
          link.click();
        });
    },
  },
};
