export default {
  namespaced: true,
  state: {
    unitUsers: [],
    count: 0,
  },
  getters: {
    unitUsers: (state) => state.unitUsers,
    count: (state) => state.count,
  },
  mutations: {
    unitUsers: (state, payload) => (state.unitUsers = payload),
    count: (state, payload) => (state.count = payload),
  },
  actions: {
    list({ commit }, queryParams) {
      return this.$apiService
        .get("/unit-users", {
          params: queryParams,
        })
        .then((res) => {
          commit("unitUsers", res?.data?.data ?? []);
          commit("count", res?.data?.count ?? 0);
          return res;
        });
    },
    create({}, payload) {
      return this.$apiService.post("/unit-users", payload);
    },
    showByUserId({}, queryParams) {
      return this.$apiService
        .get("/show-unit-user", {
          params: queryParams,
        })
        .then((res) => {
          return res;
        });
    },
    show({}, id) {
      return this.$apiService.get(`/unit-users/${id}`);
    },
    update({}, { id, data }) {
      return this.$apiService.put(`/unit-users/${id}`, data);
    },
    destroy({}, id) {
      return this.$apiService.delete(`/unit-users/${id}`);
    },
    deleteUnitUsers({}, payload) {
      return this.$apiService.post("/delete-unit-users", payload);
    },
  },
};
