import Vue from "vue";
import Vuex from "vuex";
import NotificationService from "@/services/notification.service";
import documentApiService from "../utils/DocumentApiService";
import authApiService from "../utils/AuthApiService";
import apiService from "../utils/apiService";

// Modules
import app from "./app";
import auth from "./auth";
import apikeys from "./apikeys";
import units from "./units";
import flags from "./flags";
import packages from "./packages";
import unitUsers from "./unitUsers";
import caseLists from "./caseLists";
import permissions from "./permissions";
import roles from "./roles";
import users from "./users";
import customers from "./customers";
import news from "./news";
import documentService from "./documentService";
import invoices from "./invoices";
import invoiceReminderLevel from "./invoiceReminderLevel";
import tickets from "./tickets";
import ticketComments from "./ticketComments";

import globalConfiguration from "./globalconfiguration";
import appConfig from "./app-config";
import verticalMenu from "./vertical-menu";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    pusher: null,
    isLoading: false,
    showLoader: false,
    errors: {},
    flashMessage: {
      show: false,
      message: "",
      type: "",
      link: "",
    },
    isPublic: false,
    language: "en",
  },
  getters: {
    pusher: (state) => state.pusher,
    errors: (state) => state.errors,
    flashMessage: (state) => state.flashMessage,
    isLoading: (state) => state.isLoading,
    showLoader: (state) => state.showLoader,
    isPublic: (state) => state.isPublic,
    language: (state) => state.language,
  },
  mutations: {
    pusher: (state, payload) => (state.pusher = payload),
    language: (state, payload) => (state.language = payload),
    errors: (state, payload) => {
      const tempErrors = {};
      for (let key in typeof payload === "object" ? payload : {}) {
        tempErrors[key] =
          payload[key] instanceof Array ? payload[key]?.[0] : payload[key];
      }
      state.errors = tempErrors;
    },
    flashMessage: (state, payload) => {
      setTimeout(() => {
        state.flashMessage = payload;
        if (payload.show === true) {
          if ((payload.type = "error"))
            NotificationService.showError(payload.message);
          else {
            NotificationService.showInfo(payload.message);
          }
        }
      }, 1);
    },
    isLoading: (state, payload) => (state.isLoading = payload),
    showLoader: (state, payload) => (state.showLoader = payload),

    isPublic: (state, payload) => (state.isPublic = payload),
  },
  actions: {
    showResponse({ commit }, { type, message, errors, error, link }) {
      if (type === "success") {
        commit("flashMessage", {
          show: true,
          message: message,
          type: "success",
          link: link,
        });
      } else if (type === "error") {
        commit("errors", errors, {
          root: true,
        });
        commit("flashMessage", {
          show: true,
          message: message,
          type: "error",
          link: link,
        });
        throw new Error(message, { cause: error });
      }
    },
  },
  modules: {
    app,
    appConfig,
    verticalMenu,
    auth,
    apikeys,
    users,
    permissions,
    roles,
    caseLists,
    customers,
    news,
    invoices,
    documentService,
    invoiceReminderLevel,
    units,
    unitUsers,
    flags,
    packages,
    globalConfiguration,
    tickets,
    ticketComments
  },
  strict: process.env.DEV,
});

store.$authApiService = authApiService;
store.$apiService = apiService;
store.$documentApiService = documentApiService;

export default store;
