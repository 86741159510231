export default {
  namespaced: true,
  state: {
    units: [],
    count: 0,
  },
  getters: {
    units: (state) => state.units,
    count: (state) => state.count,
  },
  mutations: {
    units: (state, payload) => (state.units = payload),
    count: (state, payload) => (state.count = payload),
  },
  actions: {
    list({ commit }, queryParams) {
      return this.$apiService
        .get("/units", {
          params: queryParams,
        })
        .then((res) => {
          commit("units", res?.data?.data ?? []);
          commit("count", res?.data?.count ?? 0);
          return res;
        });
    },
    showByUserId({}, queryParams) {
      return this.$apiService
        .get("/show-unit", {
          params: queryParams,
        })
        .then((res) => {
          return res;
        });
    },
    create({}, payload) {
      return this.$apiService.post("/units", payload);
    },
    show({}, id) {
      return this.$apiService.get(`/units/${id}`);
    },
    update({}, { id, data }) {
      return this.$apiService.put(`/units/${id}`, data);
    },
    destroy({}, id) {
      return this.$apiService.delete(`/units/${id}`);
    },
    deleteUnits({}, payload) {
      return this.$apiService.post("/delete-units", payload);
    },

    detachUnit({}, payload) {
      return this.$apiService.post("/detach-unit", payload);
    },
  },
};
