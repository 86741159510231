import axios from "axios";
import { control } from "leaflet";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import TokenService from "./token.service";
import NotificationService from "../services/notification.service";
import { mustGetEnv } from "@/utils/env";

let baseURL = mustGetEnv("VUE_APP_PORTAL_BASEURL");

const restClient = axios.create({
  baseURL: baseURL,
  headers: {
    "Content-Type": "application/json",
  },
});

// before a request is made start the nprogress
restClient.interceptors.request.use((config) => {
  //NProgress.start();
  NProgress.inc();

  const token = TokenService.getLocalAccessToken();
  if (token) {
    config.headers["Authorization"] = "Bearer " + token;
  }

  return config;
});

// Add a response interceptor
restClient.interceptors.response.use(
  function (response) {
    NProgress.done();
    return response;
  },
  async function (err) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    NProgress.done();
    const originalConfig = err.config;
    console.log(err);
    if (err.response) {
      if (originalConfig.url === "/portal-service/login") {
        // Error occurred while trying to login
        console.log("Oops something went wrong");
      } else if (originalConfig.url === "/portal-service/refresh-token") {
        // Error occurred while trying to refresh
        if (err.response.status === 401 || err.response.status === 403) {
          // Show login screen
          console.log("User needs to login with mail/password");
        }
      } else {
        if (err.response.status === 401 || err.response.status === 403) {
          // Access Token was expired
          if (!originalConfig._retry) {
            originalConfig._retry = true;
            try {
              const rs = await axiosInstance.post(
                "/portal-service/refresh-token",
                {
                  refresh_token: TokenService.getLocalRefreshToken(),
                }
              );
              store.dispatch("auth/refreshToken", rs.data["token"]);
              TokenService.updateLocalAccessToken(rs.data["token"]);
              TokenService.updateLocalRefreshToken(rs.data["refresh_token"]);
              return axiosInstance(originalConfig);
            } catch (_error) {
              return Promise.reject(_error);
            }
          }
        } else {
          console.log("error!");
          console.log(err.response);
          if (typeof err.response.data.msg !== "undefined") {
            NotificationService.showError(err.response.data.msg);
          } else {
            NotificationService.showError(err.response.statusText);
          }
        }
      }
    } else {
      NotificationService.showError(err);
    }

    return Promise.reject(err);
  }
);

export default restClient;
