export default {
  namespaced: true,
  state: {
    configuration: {},
  },
  getters: {
    configuration: (state) => state.configuration,
  },
  mutations: {
    configuration: (state, payload) => (state.configuration = payload),
  },
  actions: {
    show({ commit }) {
      return this.$apiService
        .get(`/get-global-configuration`)
        .then((response) => {
          commit("configuration", response?.data?.data ?? {});
          return response;
        });
    },
  },
};
