import Vue from "vue";
import VueRouter from "vue-router";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import AuthService from "../services/auth.service";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/home",
      name: "home",
      component: () => import("@/views/dashboard/Index.vue"),
      meta: {
        pageTitle: "Home",
        requiresAuth: true,
      },
    },
    {
      path: "/news",
      name: "news",
      component: () => import("@/views/news/Index.vue"),
      meta: {
        pageTitle: "News",
        requiresAuth: true,
      },
    },
    {
      path: "/new-case",
      name: "new-case",
      component: () => import("@/views/my-cases/create/Index.vue"),
      meta: {
        pageTitle: "New Case",
        requiresAuth: true,
      },
    },
    {
      path: "/my-cases/active",
      name: "my-cases-active",
      component: () => import("@/views/my-cases/active/Index.vue"),
      meta: {
        pageTitle: "Active",
        requiresAuth: true,
      },
    },
    {
      path: "/my-cases/flagged",
      name: "my-cases-flagged",
      component: () => import("@/views/my-cases/flagged/Index.vue"),
      meta: {
        pageTitle: "Flagged",
        requiresAuth: true,
      },
    },
    {
      path: "/my-cases/finished",
      name: "my-cases-finished",
      component: () => import("@/views/my-cases/archive/Index.vue"),
      meta: {
        pageTitle: "Archive",
        requiresAuth: true,
      },
    },
    {
      path: "/my-cases/trash",
      name: "my-cases-trash",
      component: () => import("@/views/my-cases/trash/Index.vue"),
      meta: {
        pageTitle: "Trash",
        requiresAuth: true,
      },
    },
    // {
    //   path: "/my-cases/preview-model",
    //   name: "my-cases-preview",
    //   component: () => import("@/views/my-cases/components/PreviewModel.vue"),
    //   meta: {
    //     pageTitle: "Active",
    //     requiresAuth: true,
    //   },
    // },
    {
      path: "/account",
      name: "account",
      component: () => import("@/views/my-info/account/Index.vue"),
      meta: {
        pageTitle: "Account",
        requiresAuth: true,
      },
    },
    {
      path: "/cancel",
      name: "cancel",
      component: () => import("@/views/cancel/Index.vue"),
      meta: {
        pageTitle: "Transaction Failed",
        requiresAuth: true,
      },
    },
    {
      path: "/success",
      name: "success",
      component: () => import("@/views/success/Index.vue"),
      meta: {
        pageTitle: "Payment Successful",
        requiresAuth: true,
      },
    },
    {
      path: "/storage",
      name: "storage",
      component: () => import("@/views/my-info/storage/Index.vue"),
      meta: {
        pageTitle: "Storage",
        requiresAuth: true,
      },
    },
    {
      path: "/credits",
      name: "credits",
      component: () => import("@/views/my-info/credits/Index.vue"),
      meta: {
        pageTitle: "Credits",
        requiresAuth: true,
      },
    },
    {
      path: "/invoices",
      name: "invoices",
      component: () => import("@/views/my-info/invoices/Index.vue"),
      meta: {
        pageTitle: "Invoices",
        permission: "invoice",
        requiresAuth: true,
      },
    },
    {
      path: "/invoices/:id/show",
      name: "invoices/show",
      component: () => import("@/views/my-info/invoices/Show.vue"),
      meta: {
        pageTitle: "Show Invoice",
        permission: "invoice",
        requiresAuth: true,
      },
    },
    // {
    //   path: "/second-page",
    //   name: "second-page",
    //   component: () => import("@/views/SecondPage.vue"),
    //   meta: {
    //     pageTitle: "Second Page",
    //   },
    // },
    // {
    //   path: "/packages",
    //   name: "packages",
    //   component: () => import("@/views/packages/Packages.vue"),
    //   meta: {
    //     pageTitle: "Extension Packages",
    //   },
    // },
    // {
    //   path: "/imprint",
    //   name: "imprint",
    //   component: () => import("@/views/Imprint.vue"),
    //   meta: {
    //     pageTitle: "Imprint",
    //   },
    // },
    // {
    //   path: "/dataprivacy",
    //   name: "dataprivacy",
    //   component: () => import("@/views/DataPrivacy.vue"),
    //   meta: {
    //     pageTitle: "Data Privacy",
    //   },
    // },
    // {
    //   path: "/invoices",
    //   name: "invoices",
    //   component: () => import("@/views/InvoiceList.vue"),
    //   meta: {
    //     pageTitle: "Invoices",
    //   },
    // },
    {
      path: "/account-setting",
      name: "account-setting",
      component: () => import("@/views/account-setting/AccountSetting.vue"),
      meta: {
        pageTitle: "Account Settings",
        requiresAuth: true,
      },
    },
    {
      path: "/faq",
      name: "faq",
      component: () => import("@/views/faq/Index.vue"),
      meta: {
        pageTitle: "FAQ",
        requiresAuth: true,
      },
    },
    {
      path: "/apps/invoice/preview/:id",
      name: "apps-invoice-preview",
      component: () => import("@/views/InvoicePreview.vue"),
    },
    {
      path: "/2d-to-3d",
      name: "2d-to-3d",
      component: () => import("@/views/2DTo3D.vue"),
      meta: {
        pageTitle: "2D to 3D Reconstruction",
        requiresAuth: true,
      },
    },
    {
      path: "/cloud-storage",
      name: "cloud-storage",
      component: () => import("@/views/CloudStorage.vue"),
      meta: {
        pageTitle: "Cloud Storage",
        requiresAuth: true,
      },
    },
    {
      path: "/case-lists",
      name: "case-list",
      component: () => import("@/views/case-lists/Index.vue"),
      meta: {
        pageTitle: "Case List",
        requiresAuth: true,
      },
    },
    {
      path: "/",
      name: "login",
      component: () => import("@/views/Login.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/register",
      name: "register",
      component: () => import("@/views/Register.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/forget-password",
      name: "forget-password",
      component: () => import("@/views/ForgotPassword.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/error-404",
      name: "error-404",
      component: () => import("@/views/error/Error404.vue"),
      meta: {
        layout: "full",
        requiresAuth: true,
      },
    },
    {
      path: "/api-keys",
      name: "api-keys",
      component: () => import("@/views/api-keys/Index.vue"),
      meta: {
        pageTitle: "API Keys",
        permission: "api-keys",
        requiresAuth: true,
      },
    },
    {
      path: "/api-keys/create",
      name: "api-keys-create",
      component: () => import("@/views/api-keys/Create.vue"),
      meta: {
        pageTitle: "Create API Key",
        permission: "api-keys",
        requiresAuth: true,
      },
    },
    {
      path: "/api-keys/:id/edit",
      name: "api-keys/edit",
      component: () => import("@/views/api-keys/Edit.vue"),
      meta: {
        pageTitle: "Edit API Key",
        permission: "api-keys",
        requiresAuth: true,
      },
    },
    {
      path: "/companies",
      name: "companies",
      component: () => import("@/views/companies/Index.vue"),
      meta: {
        pageTitle: "Companies",
        permission: "companies",
        requiresAuth: true,
      },
    },
    {
      path: "/unit",
      name: "unit",
      component: () => import("@/views/unit/Index.vue"),
      meta: {
        pageTitle: "Unit",
        permission: "unit",
        requiresAuth: true,
      },
    },
    {
      path: "/companies/create",
      name: "companies-create",
      component: () => import("@/views/companies/Create.vue"),
      meta: {
        pageTitle: "Create Company",
        permission: "companies",
        requiresAuth: true,
      },
    },
    {
      path: "/companies/:id/edit",
      name: "companies/edit",
      component: () => import("@/views/companies/Edit.vue"),
      meta: {
        pageTitle: "Edit Company",
        permission: "companies",
        requiresAuth: true,
      },
    },
    {
      path: "/users",
      name: "users",
      component: () => import("@/views/users/Index.vue"),
      meta: {
        pageTitle: "Users",
        permission: "users",
        requiresAuth: true,
      },
    },
    {
      path: "/user",
      name: "user",
      component: () => import("@/views/user/Index.vue"),
      meta: {
        pageTitle: "Users",
        permission: "user",
        requiresAuth: true,
      },
    },
    {
      path: "/users/create",
      name: "users-create",
      component: () => import("@/views/users/Create.vue"),
      meta: {
        pageTitle: "Create User",
        permission: "users",
        requiresAuth: true,
      },
    },
    {
      path: "/users/:id/edit",
      name: "users/edit",
      component: () => import("@/views/users/Edit.vue"),
      meta: {
        pageTitle: "Edit User",
        permission: "users",
        requiresAuth: true,
      },
    },
    {
      path: "/permissions",
      name: "permissions",
      component: () => import("@/views/permissions/Index.vue"),
      meta: {
        pageTitle: "Permissions",
        permission: "permissions",
        requiresAuth: true,
      },
    },
    {
      path: "/permissions/create",
      name: "permissions-create",
      component: () => import("@/views/permissions/Create.vue"),
      meta: {
        pageTitle: "Create Permission",
        permission: "permissions",
        requiresAuth: true,
      },
    },
    {
      path: "/permissions/:id/edit",
      name: "permissions/edit",
      component: () => import("@/views/permissions/Edit.vue"),
      meta: {
        pageTitle: "Edit Permission",
        permission: "permissions",
        requiresAuth: true,
      },
    },
    {
      path: "/roles",
      name: "roles",
      component: () => import("@/views/roles/Index.vue"),
      meta: {
        pageTitle: "Roles",
        permission: "roles",
        requiresAuth: true,
      },
    },
    {
      path: "/roles/create",
      name: "roles-create",
      component: () => import("@/views/roles/Create.vue"),
      meta: {
        pageTitle: "Create Role",
        permission: "roles",
        requiresAuth: true,
      },
    },
    {
      path: "/roles/:id/edit",
      name: "roles/edit",
      component: () => import("@/views/roles/Edit.vue"),
      meta: {
        pageTitle: "Edit Role",
        permission: "roles",
        requiresAuth: true,
      },
    },
    {
      path: "/settings",
      name: "settings",
      component: () => import("@/views/settings/Setting.vue"),
      meta: {
        pageTitle: "Settings",
        requiresAuth: true,
      },
    },
    {
      path: "/tickets",
      name: "tickets",
      component: () => import("@/views/tickets/Index.vue"),
      meta: {
        pageTitle: "Tickets",
        requiresAuth: true,
      },
    },
    {
      path: "/tickets/create",
      name: "tickets-create",
      component: () => import("@/views/tickets/Create.vue"),
      meta: {
        pageTitle: "Create Ticket",
        requiresAuth: true,
      },
    },
    {
      path: "/tickets/:id/edit",
      name: "tickets-edit",
      component: () => import("@/views/tickets/Edit.vue"),
      meta: {
        pageTitle: "Edit Ticket",
        requiresAuth: true,
      },
    },
    {
      path: "*",
      redirect: "error-404",
      requiresAuth: true,
    },
  ],
});

// router.beforeResolve((to, from, next) => {
//   // If this isn't an initial page load.
//   if (to.name) {
//     // Start the route progress bar.
//     NProgress.start();
//   }

//   next()

// })

router.beforeEach((to, from, next) => {
  const token = localStorage.getItem("token");

  // Check if the route requires authentication and if token doesn't exist
  if (to.meta.requiresAuth && !token) {
    // Redirect to login page
    next("/");
  } else {
    // Continue to the route
    next();
  }
});

const DEFAULT_TITLE = "DentalTwin - Your Dentist as a Service";
router.afterEach((to, from) => {
  // Use next tick to handle router history correctly
  // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  Vue.nextTick(() => {
    document.title = to.meta.title || DEFAULT_TITLE;
  });

  // Complete the animation of the route progress bar.
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
  NProgress.done();
});

export default router;
